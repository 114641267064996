.dataRow .details .table-wrap thead {
  background-color:var(--white);
}

.table-tools .table-account{
  background-color:transparent;
  color: #000;

}
.table-tools .table-account .table-name{
  margin-left: 8px;
  margin-right: 10px;
}

.dataRow .details .table-wrap thead td {
  font-weight: 600;

}

.dataRow .details .table-wrap table tr {
  color: var(--black1);
  border-bottom: 1px solid var(--black3);


}

.dataRow .details .table-wrap table tr:last-child {
  border-bottom: none;
}

.dataRow .details .table-wrap table tr td {
  padding: 2px;


}

.dataRow .details .table-wrap table tbody tr {
  background-color: rgba(229, 215, 215, 0.1);
  font-weight: bold;
}


.dataRow .details .table-wrap table tbody tr:hover {
  background-color: rgba(15, 7, 7, 0.1);
  color: var(--orange);
  font-weight: bold;
}

/***********************************/

.dataRow-with-two-column .details .table-wrap thead {
  background-color: var(--white);
}

.dataRow-with-two-column .details .table-wrap thead td {
  font-weight: 600;

}

.dataRow-with-two-column .details .table-wrap table {
  width: 100%;


}

.dataRow-with-two-column .details .table-wrap table tr {
  color: var(--black1);
  border-bottom: 1px solid var(--black3);


}

.dataRow-with-two-column .details .table-wrap table tr:last-child {
  border-bottom: none;
}

.datadataRow-with-two-column .details .table-wrap table tr td {
  padding: 2px;


}
.dataRow-with-two-column .details .table-wrap table tbody tr{
  background-color: rgba(229, 215, 215, 0.1);
  color: var(--black1);
  font-weight: bold;
}


.dataRow-with-two-column .details .table-wrap table tbody tr:hover {
  background: rgba(141, 136, 136, 0.1);
  color: var(--orange);
  font-weight: bold;
}

.dataRow-with-two-column-modal .details .table-wrap table {
  width: 100%;
  min-width: 100%;
  max-height: 350px;
  display: inherit;


}

.dataRow-with-two-column-center .details tfoot {
  /* background-color: rgb(158, 123, 123); */
  color: black;
  font-size: 18px;
  font-weight: bold;


}

.dataRow-with-two-column-modal .details .table-wrap table tr {
  color: var(--black1);
  border-bottom: 1px solid var(--black3);
  max-height: 5px;


}

.dataRow-with-two-column-modal .details .table-wrap table tr:last-child {
  border-bottom: none;
}

.datadataRow-with-two-column-modal .details .table-wrap table tr td {
  padding: 0px;


}

.datadataRow-with-two-column-modal .details .table-wrap table td {
  max-height: 10px;


}




.dataRow-with-two-column-modal .details .table-wrap table tbody tr:hover {
  background: rgba(141, 136, 136, 0.1);
  color: var(--orange);
  font-weight: bold;
}

/***********************************/


.table-title {
  font-weight: bold;
  font-size: 15px;

}

.status.delivered {
  padding: 2px 4px;
  background: #8de02c;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Pending {
  padding: 2px 4px;
  background: rgb(241, 147, 6);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Completed {
  padding: 2px 4px;
  background: rgb(67, 184, 238);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Duplicate {
  padding: 2px 4px;
  background: rgb(231, 109, 130);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Reste {
  padding: 2px 4px;
  background: rgb(236, 75, 102);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.PAYED {
  padding: 2px 4px;
  background: rgb(44, 173, 5);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.DEPOSIT {
  padding: 2px 4px;
  background: var(--orange);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.PENDING {
  padding: 2px 4px;
  background: rgb(234, 0, 255);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.ERROR {
  padding: 2px 4px;
  background: red;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Medium {
  padding: 2px 4px;
  background: var(--blue);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.High {
  padding: 2px 4px;
  background: var(--orange);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Full {
  padding: 2px 4px;
  background: var(--green);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.NORMAL-PRICE {
  padding: 2px 4px;
  background: var(--green);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.LOW-PRICE {
  padding: 2px 4px;
  background: var(--red);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.AUTHORIZED {
  padding: 2px 4px;
  background: #c10fc7;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Low {
  padding: 2px 4px;
  background: var(--orange);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Critical {
  padding: 2px 4px;
  background: var(--red);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.TOWN1 {
  padding: 2px 4px;
  background: rgb(145, 5, 173);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.NYABUGOGO1 {
  padding: 2px 4px;
  background: rgb(58, 173, 5);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.PHONE{
  padding: 2px 4px;
  background: rgb(58, 173, 5);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.ACCESSORIES{
  padding: 2px 4px;
  background: rgb(5, 16, 173);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}



.table-action-button {
  min-width: 100px;
  max-width: 100px;
  background-color: transparent;
}

.table-buttons {
  min-width: 35px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
}

.table-buttons-l {
  min-width: 75px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
}

.table-buttons-xl {
  min-width: 95px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
}

.table-buttons-xl :hover {
  min-width: 95px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
  background-color: #710874;
  color: white;
}

.table-buttons :hover {

  color: #c10fc7;
   
}

.dataRow-modal-header {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1, 2fr);
}

.dataRow-modal-header .button-confirmation-stockkeeper {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-green {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#65ef56,#468d07 );
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-yellow {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#e5d068,#9e9b0c );
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-red {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#e7785c,#ec2c0e );
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#f07809, #f07809);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-green:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#31302f, #31302f);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-yellow:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#31302f, #31302f);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-red:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#31302f, #31302f);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .details {

  width: 25%;
  min-height: 200px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

}