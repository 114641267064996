@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');


.pages {
  display: inherit;
  width: 100%;
  height: 100%;
  border-top: 4px solid var(--table-tr-boarder);
 
  padding:10px;

  background: #9f5454;
  background: -moz-linear-gradient(top, #F4F4F4, #EEEEEE);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#F4F4F4), to(#EEEEEE));

  
}

.modal-bg{
  background: #F4F4F4;
  background: -moz-linear-gradient(top, #F4F4F4, #EEEEEE);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#F4F4F4), to(#EEEEEE));
  padding-bottom: 10px;
}



/*================================card============================================*/
.tool-box{
  
  min-width: 350px;
  max-width: 350px;
  height: 130px;
  max-height: 130px;
  background-color: rgba(62, 57, 57, 0.1);
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0;
  border-radius: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0,0.1);
  color: var(--white);
  cursor: pointer;
  border:1px solid #fff;

}

.tool-box .page-title{
  font-weight: bold;
  font-size: 20px;
  padding-top: 0px;
  color: var(--black1);
  margin-left: 20px;
}

.tool-box .page-title :hover{
color: var(--white);
}

.tool-box .page-title-img{
  font-size: 60px;
  color: var(--orange);
}

.tool-box .page-title-img :hover{
color: var(--white);
}

.tool-box .table-tools{
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  font-weight: normal;
  color: var(--orange);

}

.tool-box .page-tile{
  
  margin-top: 0px;
  font-weight: bold;
  color: var(--black);

}

.table-tools-img{
  color: var(--orange);
}

.table-tools-printer{
  color: var(--white);
  font-size: 25px;
}

.table-tools-img-search{
  color: var(--orange);
}

.tool-box .table-tools tr{
  
  border: 0px solid;
  
}

.tool-box:hover{
background-color: rgba(17, 12, 12, 0.1);
color: var(--white);
}

.tool-box .table-tools:hover{
  
  background-color: rgba(222, 201, 201, 0.1);
color: var(--white);
  
}

.tool-box .table-tools .search-input{
  max-width: 200px;
  min-height: 30px;
  max-height: 30px;
  background-color: #0d70ea;
  border-radius: 40px;
  border:1px solid #c0bbbb;
  background-color: var(--white);
  color: var(--black1);
}

.search-input{
  max-width:95%;
  min-height: 30px;
  max-height: 30px;
  background-color: #0d70ea;
  border-radius: 40px;
  border:1px solid #c0bbbb;
  background-color: var(--white);
  color: var(--black);
  font-weight: bold;
}

/*payment */
.tool-box-payment{
  
  min-width: 350;
  max-width: 350;
  height: 400px;
  max-height: 400px;
  background-color:var(--white);
  margin-top: 70px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0,0.1);
  color: var(--black1);
  font-style: normal ;
  font-weight: bolder;
  cursor: pointer;

}

.tool-box-payment .title-img{
  font-size: 50px;
}

.tool-box-payment-sm{
  
  min-width: 350px;
  max-width: 350px;
  height: 300px;
  max-height: 300px;
  background-color:var(--white);
  margin-top: 70px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0,0.1);
  color: var(--black1);
  font-style: normal ;
  font-weight: bolder;
  cursor: pointer;

}

.tool-box-payment-sm .title{
  font-size: 30px;
}

.tool-box-payment-sm .title-img{
  font-size: 50px;
}

.tool-box-payment-sm .table-tools{
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  font-weight: normal;
  color: var(--orange);

}



.tool-box-payment-sm .table-tools tr{
  
  border: 0px solid;
  
}


/*end payment */

.datePicker-style{
  height: 30px;
  max-height: 30px;
  width: 120px;
  max-width: 120px;
  font-weight: bold;
  margin-left: 1px;
}

.pagination-box{
  margin-left: 70px;
}

.dataRow{
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1,2fr);
}

.dataRow .details{

   max-width: 100%;
   min-width: 90%;
   min-height: 400px;
   background-color: rgba(166, 153, 153, 0.1);
   padding: 0px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-sm{

  width: 100%;
   min-height: 200px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-sm-2{

  width: 90%;
   /* min-height: 200px; */
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-home {

  width: 30%;
  min-height: 200px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

}

.dataRow .details-dialog-sm{

  width: 100%;
  
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-printing{

  width: 100%;
   min-height:100%;
   background-color: white;
   padding: 20px;
   border-radius: 20px;
   font-family: 'Poppins', sans-serif;
   font-size: 16px;
   color: black;

}

.dataRow .details-printing .table2{

  
   font-family:'Courier New', Courier, monospace;
   font-size: 16px;
   font-weight: bold;
   color: black;
   background-color: white;

}



.dataRow .details-medium{

  width: 70%;
   min-height: 400px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-small{

  width: 50%;
   min-height: 400px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-search{

  width: 100%;
   min-height: 200px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}


.dataRow .details .table-wrap table{
  min-width: 100%;
  max-width: 100%;
  height: 97%;
  max-height: 97%;
  overflow-y: scroll;
  overflow-x: scroll;
   

}

.dataRow .details .table-wrap{
  min-width: 100%;
  min-height: 100px;
  max-height: 100%;
  overflow-y: auto;
    /* Rounded Corners */
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
    

}


.dataRow-with-two-column
 {
 
   width: 100%;
   padding: 20px;
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 30px;
   margin-top: 150px;
   background-color: rgba(229, 215, 215, 0.1);
 }

 .dataRow-with-two-column-center
 {
 
   width: 100%;
   padding: 20px;
   display: grid;
   grid-template-columns: 2fr 2fr;
   grid-gap: 30px;
   margin-top: 150px;
   
 }

 .dataRow-with-two-column-cashing
 {
 
   width: 100%;
   padding: 20px;
   display: grid;
   grid-template-columns: 3fr 2fr 2fr 2fr;
   grid-gap: 5px;
   margin-top: 5px;
 }


 .dataRow-with-two-column .details 
 {
  
   min-height: 90%;
   background-color: rgba(229, 215, 215, 0.1);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

 }

 .dataRow-with-two-column-center .details 
 {
  
   min-height: 90%;
   background: rgba(229, 215, 215, 0.1);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
   border-radius: 20px;

 }

 .dataRow-with-two-column-cashing .details 
 {
  
   min-height: 90%;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

 }


 .dataRow-with-two-column .details .table-wrap{
   
  min-height: 95px;
  max-height: 450px;
  position:inherit;
  overflow:scroll;
  
       
}

.dataRow-with-two-column-modal
 {
 
   width: 100%;
   min-height: 400px;
   max-height: 450px;
   padding: 20px;
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 30px;
   margin-top: 4px;
 }

 .dataRow-with-two-column-modal .details 
 {
  
   min-height: 400px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;
 }

 .dataRow-with-two-column-modal .details .table-wrap table{
   
  min-height: 95%;
  max-height: 95%;
  overflow-y: auto;
  
}



 /*==================Buttons===============================*/

 .button-tools {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools:hover{
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-head-cashier-report {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-head-cashier-report:hover {
  background-image: linear-gradient(#595454, #595454);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-stock-keeper {
  background-image: linear-gradient(rgb(15, 124, 13), rgb(15, 124, 13));
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-stock-keeper:hover {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

  

.button-tools-home {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-home:hover{
  background: rgba(119, 119, 119, 0.1);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(226, 135, 15, 0.3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-search {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-search:hover {
  background-image: linear-gradient(rgb(149, 142, 142), rgb(216, 209, 209));
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-search-red {
  background-image: linear-gradient(#da8851, #ec7628);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-search-red:hover {
  background-image: linear-gradient(#e9f2f3, #d7dce2);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-product-div{

  max-width: 80%;
  

}

.button-tools-product {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 1px 1px;
  min-width: 50%;
  max-width: 30%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-product:hover {
  background-image: linear-gradient(#e9f2f3, #d7dce2);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: rgb(236, 98, 98);
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 1px 1px;
  max-width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-sm {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-sm:hover {
  background-image: linear-gradient(#b8eff5, #bfd4ec);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: rgb(209, 85, 85);
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-md {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 80px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-red {
  background-image: linear-gradient(rgb(203, 89, 89), rgb(204, 104, 104));
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-red:hover{
  background-image: linear-gradient(#ed4a4a, #d0563e);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: rgb(250, 246, 245);
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-green {
  background-image: linear-gradient(#1b940b,#1b940b);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-cashing{

  padding: 2px;
  background-image: linear-gradient(#c90fb9,#c90fb9);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  width: 50px;

}

.button-tools-violet {
  background-image: linear-gradient(#c90fb9,#c90fb9);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-search {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-table{
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 70px;
  max-height: 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form-modal-sm{
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-form:hover {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form-modal-sm:hover {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-form-sm {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 0px;
  width: 110px;
  max-height: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-form-sm:hover{
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color:rgb(233, 91, 20);
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 0px;
  width: 110px;
  max-height: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form-lg {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 0px;
  width: 135px;
  max-height: 50px;
  min-height: 40px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-profile {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  /* min-width: 80%; */
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-profile:hover{
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  /* min-width: 80%; */
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-profile .img{

  font-size: 16px;
  
  font-weight: bold;
  margin-left: 10px;

}

.danger-message{
  color: var(--red);
}

.newpassword{

  color: var(--red);
  font-weight: bold;
  margin-left: 10px;

}

.newpassword-btn{

  padding: 5px;
  margin-left: 50px;

}

#wrapper{
  background:transparent;
  overflow:auto;
  padding-right: 120px;
}

#c0{
  float:left;
  background:transparent;
  width: 100%;
  padding: 20px;
  font-weight: bold;
}

#c1{
  float:right;
  background:transparent;
}

#c2{
  background:transparent;
    float:right;
    margin-right: 70px;
    padding-top: 5px;
    font-size: larger;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    
}

.c2{
  background:transparent;
    float:right;
    margin-right: 70px;
    padding-top: 5px;
     
    
    
}

.c2 .table2{

  
  font-family:'Courier New', Courier, monospace;
  font-size: 16px;
  font-weight: bold;
  color: black;
  background-color: white;

}

.c2 .c2-table{
  
    font-size: larger;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
    
}

.payment-img{
  color: var(--blue2);
  font-size: 20px;
  background-color: var(--white);
  padding:2px;
  border-radius: 40px;
  max-height: 40px;
}

.select-option{
  min-width: 120px;
}

.total-text-grand{
  background-color: rgb(105, 97, 97);
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
}

.total-text{
  /* background-color: rgb(146, 142, 142); */
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
}

.comment-text-field{
  min-height: 100px;
  margin-left: 5px;
}

.top-logo-image{
  background:transparent;
    float:right;
    margin-right: 70px;
    padding-top: 5px;
    font-size: larger;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    
}

.custom-marging{
  margin-left: 20px;
  margin-right: 10px;
}




 
