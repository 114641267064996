@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap);

 
:root
{
  --blue: #3c73cc;
  --blue2: #a3c1f1;
  --white: #fff;
  --grey: #f5f5f5;
  --black1: #222;
  --black2: #999;
  --black3: rgb(129, 125, 125);
  --orange: #db7515
   
}



/* printing */

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 0rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

.printing-page{
  width: 100%;
  height: 100%;
  background-color:white;
}

.form-control{
    margin: 4px;
    border-radius: 40px;
    
  }

  .form-input-text{
      border-radius: 40px;
  }

  .alert-success{
      color: var(--green);
  }

  .alert-danger{
    color: var(--red);
}
.login {
   
    min-width: 100%;
    min-height: 1000px;
    overflow: hidden;
    background-color: #9fa1a3;
}

  

.login a {
    color: #92badd;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
  }
  
  .login h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px; 
    color: #cccccc;
  }
  
  
  
  /* STRUCTURE */
  
  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
    
  }
  
  #formContent {
    border-radius: 10px 10px 10px 10px;
    background: #b5b7b8;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    border: 1px solid #fff;
    position: relative;
    padding: 0px;
    box-shadow: 0 10px 30px 0 rgba(239, 243, 245, 0.4);
    text-align: center;
  }
  
  #formFooter {
    background-color:#b5b7b8;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    border-radius: 0 0 10px 10px;
  }
  
  
  
  /* TABS */
  
  h2.inactive {
    color: #cccccc;
  }
  
  h2.active {
    color:black;
    border-bottom: 2px solid #fff;
    font-family: 'Josefin Sans';
  }
  
  
  
  /* FORM TYPOGRAPHY*/
  
  .login button[type=button], input[type=submit], input[type=reset]  {
    background-color: #b5b7b8;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    transition: all 0.3s ease-in-out;
  }

  .login-button  {
    background-color: #727576;
    border: none;
    color: #fff;
    padding: 7px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Josefin Sans';
    box-shadow: 0 10px 30px 0 rgba(239, 243, 245, 0.4);
    border-radius: 50px 50px 5px 5px;
    margin: 5px 20px 40px 20px;
    transition: all 0.3s ease-in-out;
  }
  
  .login [type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
    background-color: #39ace7;
  }
  
  .login input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
    transform: scale(0.95);
  }
  
  .login input[type=text] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: 'Josefin Sans';
    margin: 5px;
    width: 70%;
    border: 2px solid #f6f6f6;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
  }

  .login input[type=password] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: 'Josefin Sans';
    margin: 5px;
    width: 70%;
    border: 2px solid #f6f6f6;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
  }
  
  .login input[type=text]:focus {
    background-color: #fff;
    border-bottom: 2px solid #727576;
  }

  .login input[type=password]:focus {
    background-color: #fff;
    border-bottom: 2px solid #727576;
  }
  
  .login input[type=text]::placeholder {
    color: #cccccc;
  }

  .login input[type=password]::placeholder {
    color: #cccccc;
  }
  
  
  
  /* ANIMATIONS */
  
  /* Simple CSS3 Fade-in-down Animation */
  .login .fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
  
  /* Simple CSS3 Fade-in Animation */
  @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  
  .login .fadeIn {
    opacity:0;
    animation:fadeIn ease-in 1;
    animation-fill-mode:forwards;
    animation-duration:1s;
  }
  
  .login .fadeIn.first {
    animation-delay: 0.4s;
  }
  
  .login .fadeIn.second {
    animation-delay: 0.6s;
  }
  
  .login .fadeIn.third {
    animation-delay: 0.8s;
  }
  
  .login .fadeIn.fourth {
    animation-delay: 1s;
  }
  
  /* Simple CSS3 Fade-in Animation */
  .login .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
  }
  
  .login .underlineHover:hover {
    color: #0d0d0d;
  }
  
  .login .underlineHover:hover:after{
    width: 100%;
  }
  
  
  
  /* OTHERS */
  
  *:focus {
      outline: none;
  } 
  
  .login #icon {
    width:30%;
    background: lavender;
    object-fit: cover;
    border-radius: 50%;
  }
  
  * {
    box-sizing: border-box;
  }

  .logo-img{
    width: 150px;
    height: 120px;
    padding: 5px;
  }
.pages {
  display: inherit;
  width: 100%;
  height: 100%;
  border-top: 4px solid var(--table-tr-boarder);
 
  padding:10px;

  background: #9f5454;
  background: -moz-linear-gradient(top, #F4F4F4, #EEEEEE);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#F4F4F4), to(#EEEEEE));

  
}

.modal-bg{
  background: #F4F4F4;
  background: -moz-linear-gradient(top, #F4F4F4, #EEEEEE);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#F4F4F4), to(#EEEEEE));
  padding-bottom: 10px;
}



/*================================card============================================*/
.tool-box{
  
  min-width: 350px;
  max-width: 350px;
  height: 130px;
  max-height: 130px;
  background-color: rgba(62, 57, 57, 0.1);
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0;
  border-radius: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0,0.1);
  color: var(--white);
  cursor: pointer;
  border:1px solid #fff;

}

.tool-box .page-title{
  font-weight: bold;
  font-size: 20px;
  padding-top: 0px;
  color: var(--black1);
  margin-left: 20px;
}

.tool-box .page-title :hover{
color: var(--white);
}

.tool-box .page-title-img{
  font-size: 60px;
  color: var(--orange);
}

.tool-box .page-title-img :hover{
color: var(--white);
}

.tool-box .table-tools{
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  font-weight: normal;
  color: var(--orange);

}

.tool-box .page-tile{
  
  margin-top: 0px;
  font-weight: bold;
  color: var(--black);

}

.table-tools-img{
  color: var(--orange);
}

.table-tools-printer{
  color: var(--white);
  font-size: 25px;
}

.table-tools-img-search{
  color: var(--orange);
}

.tool-box .table-tools tr{
  
  border: 0px solid;
  
}

.tool-box:hover{
background-color: rgba(17, 12, 12, 0.1);
color: var(--white);
}

.tool-box .table-tools:hover{
  
  background-color: rgba(222, 201, 201, 0.1);
color: var(--white);
  
}

.tool-box .table-tools .search-input{
  max-width: 200px;
  min-height: 30px;
  max-height: 30px;
  background-color: #0d70ea;
  border-radius: 40px;
  border:1px solid #c0bbbb;
  background-color: var(--white);
  color: var(--black1);
}

.search-input{
  max-width:95%;
  min-height: 30px;
  max-height: 30px;
  background-color: #0d70ea;
  border-radius: 40px;
  border:1px solid #c0bbbb;
  background-color: var(--white);
  color: var(--black);
  font-weight: bold;
}

/*payment */
.tool-box-payment{
  
  min-width: 350;
  max-width: 350;
  height: 400px;
  max-height: 400px;
  background-color:var(--white);
  margin-top: 70px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0,0.1);
  color: var(--black1);
  font-style: normal ;
  font-weight: bolder;
  cursor: pointer;

}

.tool-box-payment .title-img{
  font-size: 50px;
}

.tool-box-payment-sm{
  
  min-width: 350px;
  max-width: 350px;
  height: 300px;
  max-height: 300px;
  background-color:var(--white);
  margin-top: 70px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0,0.1);
  color: var(--black1);
  font-style: normal ;
  font-weight: bolder;
  cursor: pointer;

}

.tool-box-payment-sm .title{
  font-size: 30px;
}

.tool-box-payment-sm .title-img{
  font-size: 50px;
}

.tool-box-payment-sm .table-tools{
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  font-weight: normal;
  color: var(--orange);

}



.tool-box-payment-sm .table-tools tr{
  
  border: 0px solid;
  
}


/*end payment */

.datePicker-style{
  height: 30px;
  max-height: 30px;
  width: 120px;
  max-width: 120px;
  font-weight: bold;
  margin-left: 1px;
}

.pagination-box{
  margin-left: 70px;
}

.dataRow{
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1,2fr);
}

.dataRow .details{

   max-width: 100%;
   min-width: 90%;
   min-height: 400px;
   background-color: rgba(166, 153, 153, 0.1);
   padding: 0px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-sm{

  width: 100%;
   min-height: 200px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-sm-2{

  width: 90%;
   /* min-height: 200px; */
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-home {

  width: 30%;
  min-height: 200px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

}

.dataRow .details-dialog-sm{

  width: 100%;
  
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-printing{

  width: 100%;
   min-height:100%;
   background-color: white;
   padding: 20px;
   border-radius: 20px;
   font-family: 'Poppins', sans-serif;
   font-size: 16px;
   color: black;

}

.dataRow .details-printing .table2{

  
   font-family:'Courier New', Courier, monospace;
   font-size: 16px;
   font-weight: bold;
   color: black;
   background-color: white;

}



.dataRow .details-medium{

  width: 70%;
   min-height: 400px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-small{

  width: 50%;
   min-height: 400px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}

.dataRow .details-search{

  width: 100%;
   min-height: 200px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

}


.dataRow .details .table-wrap table{
  min-width: 100%;
  max-width: 100%;
  height: 97%;
  max-height: 97%;
  overflow-y: scroll;
  overflow-x: scroll;
   

}

.dataRow .details .table-wrap{
  min-width: 100%;
  min-height: 100px;
  max-height: 100%;
  overflow-y: auto;
    /* Rounded Corners */
  border-radius: 5px 5px 5px 5px;
    

}


.dataRow-with-two-column
 {
 
   width: 100%;
   padding: 20px;
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 30px;
   margin-top: 150px;
   background-color: rgba(229, 215, 215, 0.1);
 }

 .dataRow-with-two-column-center
 {
 
   width: 100%;
   padding: 20px;
   display: grid;
   grid-template-columns: 2fr 2fr;
   grid-gap: 30px;
   margin-top: 150px;
   
 }

 .dataRow-with-two-column-cashing
 {
 
   width: 100%;
   padding: 20px;
   display: grid;
   grid-template-columns: 3fr 2fr 2fr 2fr;
   grid-gap: 5px;
   margin-top: 5px;
 }


 .dataRow-with-two-column .details 
 {
  
   min-height: 90%;
   background-color: rgba(229, 215, 215, 0.1);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

 }

 .dataRow-with-two-column-center .details 
 {
  
   min-height: 90%;
   background: rgba(229, 215, 215, 0.1);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
   border-radius: 20px;

 }

 .dataRow-with-two-column-cashing .details 
 {
  
   min-height: 90%;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;

 }


 .dataRow-with-two-column .details .table-wrap{
   
  min-height: 95px;
  max-height: 450px;
  position:inherit;
  overflow:scroll;
  
       
}

.dataRow-with-two-column-modal
 {
 
   width: 100%;
   min-height: 400px;
   max-height: 450px;
   padding: 20px;
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-gap: 30px;
   margin-top: 4px;
 }

 .dataRow-with-two-column-modal .details 
 {
  
   min-height: 400px;
   background: var(--white);
   padding: 20px;
   box-shadow: 0 7px 25px rgba(0,0,0, 0.08);
   border-radius: 20px;
 }

 .dataRow-with-two-column-modal .details .table-wrap table{
   
  min-height: 95%;
  max-height: 95%;
  overflow-y: auto;
  
}



 /*==================Buttons===============================*/

 .button-tools {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools:hover{
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-head-cashier-report {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-head-cashier-report:hover {
  background-image: linear-gradient(#595454, #595454);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-stock-keeper {
  background-image: linear-gradient(rgb(15, 124, 13), rgb(15, 124, 13));
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-stock-keeper:hover {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

  

.button-tools-home {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-home:hover{
  background: rgba(119, 119, 119, 0.1);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(226, 135, 15, 0.3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-search {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-search:hover {
  background-image: linear-gradient(rgb(149, 142, 142), rgb(216, 209, 209));
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-search-red {
  background-image: linear-gradient(#da8851, #ec7628);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-search-red:hover {
  background-image: linear-gradient(#e9f2f3, #d7dce2);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-product-div{

  max-width: 80%;
  

}

.button-tools-product {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 1px 1px;
  min-width: 50%;
  max-width: 30%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-product:hover {
  background-image: linear-gradient(#e9f2f3, #d7dce2);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: rgb(236, 98, 98);
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 1px 1px;
  max-width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-sm {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-sm:hover {
  background-image: linear-gradient(#b8eff5, #bfd4ec);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: rgb(209, 85, 85);
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-md {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 80px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-red {
  background-image: linear-gradient(rgb(203, 89, 89), rgb(204, 104, 104));
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-tools-red:hover{
  background-image: linear-gradient(#ed4a4a, #d0563e);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: rgb(250, 246, 245);
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-green {
  background-image: linear-gradient(#1b940b,#1b940b);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-cashing{

  padding: 2px;
  background-image: linear-gradient(#c90fb9,#c90fb9);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  width: 50px;

}

.button-tools-violet {
  background-image: linear-gradient(#c90fb9,#c90fb9);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-tools-search {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-table{
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 70px;
  max-height: 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form-modal-sm{
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-form:hover {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form-modal-sm:hover {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-form-sm {
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 0px;
  width: 110px;
  max-height: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-form-sm:hover{
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color:rgb(233, 91, 20);
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 0px;
  width: 110px;
  max-height: 50px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-form-lg {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 0px;
  width: 135px;
  max-height: 50px;
  min-height: 40px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-profile {
  background-image: linear-gradient(#727576, #727576);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  /* min-width: 80%; */
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-profile:hover{
  background-image: linear-gradient(#fff, #fff);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: orange;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  width: 100%;
  /* min-width: 80%; */
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-profile .img{

  font-size: 16px;
  
  font-weight: bold;
  margin-left: 10px;

}

.danger-message{
  color: var(--red);
}

.newpassword{

  color: var(--red);
  font-weight: bold;
  margin-left: 10px;

}

.newpassword-btn{

  padding: 5px;
  margin-left: 50px;

}

#wrapper{
  background:transparent;
  overflow:auto;
  padding-right: 120px;
}

#c0{
  float:left;
  background:transparent;
  width: 100%;
  padding: 20px;
  font-weight: bold;
}

#c1{
  float:right;
  background:transparent;
}

#c2{
  background:transparent;
    float:right;
    margin-right: 70px;
    padding-top: 5px;
    font-size: larger;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    
}

.c2{
  background:transparent;
    float:right;
    margin-right: 70px;
    padding-top: 5px;
     
    
    
}

.c2 .table2{

  
  font-family:'Courier New', Courier, monospace;
  font-size: 16px;
  font-weight: bold;
  color: black;
  background-color: white;

}

.c2 .c2-table{
  
    font-size: larger;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
    
}

.payment-img{
  color: var(--blue2);
  font-size: 20px;
  background-color: var(--white);
  padding:2px;
  border-radius: 40px;
  max-height: 40px;
}

.select-option{
  min-width: 120px;
}

.total-text-grand{
  background-color: rgb(105, 97, 97);
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
}

.total-text{
  /* background-color: rgb(146, 142, 142); */
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
}

.comment-text-field{
  min-height: 100px;
  margin-left: 5px;
}

.top-logo-image{
  background:transparent;
    float:right;
    margin-right: 70px;
    padding-top: 5px;
    font-size: larger;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    
}

.custom-marging{
  margin-left: 20px;
  margin-right: 10px;
}




 

.dataRow .details .table-wrap thead {
  background-color:var(--white);
}

.table-tools .table-account{
  background-color:transparent;
  color: #000;

}
.table-tools .table-account .table-name{
  margin-left: 8px;
  margin-right: 10px;
}

.dataRow .details .table-wrap thead td {
  font-weight: 600;

}

.dataRow .details .table-wrap table tr {
  color: var(--black1);
  border-bottom: 1px solid var(--black3);


}

.dataRow .details .table-wrap table tr:last-child {
  border-bottom: none;
}

.dataRow .details .table-wrap table tr td {
  padding: 2px;


}

.dataRow .details .table-wrap table tbody tr {
  background-color: rgba(229, 215, 215, 0.1);
  font-weight: bold;
}


.dataRow .details .table-wrap table tbody tr:hover {
  background-color: rgba(15, 7, 7, 0.1);
  color: var(--orange);
  font-weight: bold;
}

/***********************************/

.dataRow-with-two-column .details .table-wrap thead {
  background-color: var(--white);
}

.dataRow-with-two-column .details .table-wrap thead td {
  font-weight: 600;

}

.dataRow-with-two-column .details .table-wrap table {
  width: 100%;


}

.dataRow-with-two-column .details .table-wrap table tr {
  color: var(--black1);
  border-bottom: 1px solid var(--black3);


}

.dataRow-with-two-column .details .table-wrap table tr:last-child {
  border-bottom: none;
}

.datadataRow-with-two-column .details .table-wrap table tr td {
  padding: 2px;


}
.dataRow-with-two-column .details .table-wrap table tbody tr{
  background-color: rgba(229, 215, 215, 0.1);
  color: var(--black1);
  font-weight: bold;
}


.dataRow-with-two-column .details .table-wrap table tbody tr:hover {
  background: rgba(141, 136, 136, 0.1);
  color: var(--orange);
  font-weight: bold;
}

.dataRow-with-two-column-modal .details .table-wrap table {
  width: 100%;
  min-width: 100%;
  max-height: 350px;
  display: inherit;


}

.dataRow-with-two-column-center .details tfoot {
  /* background-color: rgb(158, 123, 123); */
  color: black;
  font-size: 18px;
  font-weight: bold;


}

.dataRow-with-two-column-modal .details .table-wrap table tr {
  color: var(--black1);
  border-bottom: 1px solid var(--black3);
  max-height: 5px;


}

.dataRow-with-two-column-modal .details .table-wrap table tr:last-child {
  border-bottom: none;
}

.datadataRow-with-two-column-modal .details .table-wrap table tr td {
  padding: 0px;


}

.datadataRow-with-two-column-modal .details .table-wrap table td {
  max-height: 10px;


}




.dataRow-with-two-column-modal .details .table-wrap table tbody tr:hover {
  background: rgba(141, 136, 136, 0.1);
  color: var(--orange);
  font-weight: bold;
}

/***********************************/


.table-title {
  font-weight: bold;
  font-size: 15px;

}

.status.delivered {
  padding: 2px 4px;
  background: #8de02c;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Pending {
  padding: 2px 4px;
  background: rgb(241, 147, 6);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Completed {
  padding: 2px 4px;
  background: rgb(67, 184, 238);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Duplicate {
  padding: 2px 4px;
  background: rgb(231, 109, 130);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Reste {
  padding: 2px 4px;
  background: rgb(236, 75, 102);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.PAYED {
  padding: 2px 4px;
  background: rgb(44, 173, 5);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.DEPOSIT {
  padding: 2px 4px;
  background: var(--orange);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.PENDING {
  padding: 2px 4px;
  background: rgb(234, 0, 255);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.ERROR {
  padding: 2px 4px;
  background: red;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Medium {
  padding: 2px 4px;
  background: var(--blue);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.High {
  padding: 2px 4px;
  background: var(--orange);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Full {
  padding: 2px 4px;
  background: var(--green);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.NORMAL-PRICE {
  padding: 2px 4px;
  background: var(--green);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.LOW-PRICE {
  padding: 2px 4px;
  background: var(--red);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.AUTHORIZED {
  padding: 2px 4px;
  background: #c10fc7;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Low {
  padding: 2px 4px;
  background: var(--orange);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.Critical {
  padding: 2px 4px;
  background: var(--red);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.TOWN1 {
  padding: 2px 4px;
  background: rgb(145, 5, 173);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.NYABUGOGO1 {
  padding: 2px 4px;
  background: rgb(58, 173, 5);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.PHONE{
  padding: 2px 4px;
  background: rgb(58, 173, 5);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.ACCESSORIES{
  padding: 2px 4px;
  background: rgb(5, 16, 173);
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}



.table-action-button {
  min-width: 100px;
  max-width: 100px;
  background-color: transparent;
}

.table-buttons {
  min-width: 35px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
}

.table-buttons-l {
  min-width: 75px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
}

.table-buttons-xl {
  min-width: 95px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
}

.table-buttons-xl :hover {
  min-width: 95px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;
  text-align: center;
  background-color: #710874;
  color: white;
}

.table-buttons :hover {

  color: #c10fc7;
   
}

.dataRow-modal-header {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1, 2fr);
}

.dataRow-modal-header .button-confirmation-stockkeeper {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-green {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#65ef56,#468d07 );
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-yellow {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#e5d068,#9e9b0c );
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-red {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  background-image: linear-gradient(#e7785c,#ec2c0e );
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Josefin Sans';
  font-weight: bold;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#f07809, #f07809);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-green:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#31302f, #31302f);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-yellow:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#31302f, #31302f);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .button-confirmation-stockkeeper-red:hover {
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(#31302f, #31302f);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: .9em;
}

.dataRow-modal-header .details {

  width: 25%;
  min-height: 200px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

}
:root
{
  --blue:  #509ae9; /*#3c73cc*/
  --blue2: #0c44ee; 
  --blue3: rgb(189, 231, 231);
  --white: #fff;
  --grey: #f5f5f5;
  --black1: #222;
  --black2: #999;
  --read: rgb(230, 132, 76);
  --black3: rgb(129, 125, 125);
  --blue-gradient: linear-gradient(#0dccea, #3c73cc);
  --search-box-color: #0dccea;
  --table-tr-boarder:rgb(102, 99, 99);
  --green:rgb(4, 121, 56);
  --red:rgb(228, 13, 13);
  --antiquewhite:antiquewhite;
   
}

.navbar {
  position:initial;
  background-image: linear-gradient(#727576, #727576);
  width: 100%;
  height: 70px;
  display: flex;
   
  align-items: center;
  border-bottom: 15px solid var(--white);
}

.navbar .topbar{
  background-color: transparent;
  width: 100%;
  padding-top: 1px;
   
    
}

.navbar  .title{
   
  font-size: 1.7em;
  color: var(--white);
  font-weight: bold;
  float: right;
  margin-top: 1px;

}

.navbar .profile{
   
  
  float: right;
  padding-bottom: 5px;
  margin-right: 10px;
  border-radius: 40px;

}

.navbar .profile .img{

  margin-right: 1px;
  height: 30px;
  width: 30px;
  
  float:right;
  font-size: 1.5em;
   
  text-align: center;
  padding-bottom: 10px;
}
.img1{

  float: right;
  padding-bottom: 2px;
  margin-right: 10px;
  border-radius: 40px;

  
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: var(--antiquewhite);
  font-size: 1.5em;
   
  text-align: center;
  
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none; /*toggle button background*/
}

.nav-menu {
  
  background-image: linear-gradient(#727576, #727576);
  width: 200px;
  height:100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  overflow-y: scroll;
  margin-top: 10px;
  
}

.nav-menu.active {
  left: 0;
  transition: 950ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  
}

.nav-text a {
  text-decoration: none;
  color: var(--blue2);
  font-size: 15px;
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 2px;
  background-color: var(--white);
}

.nav-text a:hover {
  background-color: rgb(241, 220, 243);
  color: var(--read);
  border-radius: 50px;
}

 
.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  
  background:transparent;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.nav-span {
  margin-left: 0px;
}

 

 .nav-title{
  position:absolute;
  width: 90%;
  float: right;
  max-height: 40px;
  padding: 1px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  margin-top: 0px;
 }

/*========================NAV ICON===========================*/

.nav-icon
 {
   position: relative;
   display: block;
   min-width: 60px;
   height: 40px;
   line-height: 60px;
   text-align: center;
 }

 .nav-icon-img{

  font-size: 1.0em;
  color: white;
  background-color: white;

 }

  /*=======================logo================================*/

 .page-icon {
  width:70%;
  background: var(--white);
  object-fit: cover;
  border-radius: 60%;
}

.title-icon {
  width:70px;
  background: var(--white);
  object-fit: cover;
  border-radius: 60%;
}

/*========================SCROLL BAR=========================*/

/* width */
::-webkit-scrollbar {
  width: 7px;
  color: var(--white);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px var(--antiquewhite);
  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(161, 154, 154);
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(216, 211, 211);
}


/*======================SEARCH========================*/
.search{
  position: relative;
  width: 400px;
  margin: 0 10px;
}

.search label 
{
  position: relative;
  width: 100%;
}

.search label input
{
 
  width: 100%;
  height: 20px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid var(--black2);
}

.search-icon{

 position: absolute;
 top: 0;
 left: 10px;
 font-size: 1.2em;

}

.user{
  position:absolute;
  min-width: 40px;
  height: 50px;
  border-radius: 50%;
  overflow:hidden;
  cursor: pointer;
  margin-right: 2px;
  text-align:flex-end;

}

.user img{
   
  top:0;
  left:0;
  width: 40px;
  height: 50px;
  object-fit: cover;
}

.navbar-left{
  background-color: transparent;
  color: #fff;
  margin-left: 0px;
  padding-bottom: 60px;
}

.navbar-left .row-menu {
 
  color: white;
  background-image: linear-gradient(#727576, #727576);
  margin: 4px;
  padding-left: 20px;
  font-family: 'Josefin Sans';
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-color: aliceblue;
  border-bottom:solid 1px;
  

}

.navbar-left .row-menu:hover{

  color: orange;
  background-image: linear-gradient(#fff, #fff);
  margin: 4px;
  padding-left: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-color: rgb(241, 211, 10);
  border-bottom:solid 1px;
  cursor: pointer;
  
}

.navbar-left .section-title{
  color: rgb(209, 202, 202);
  text-align: center;
}


